<template>
  <div class="lastBox" style="background-color: #f8fcff;">
    <div class="banner"></div>
    <el-card :body-style="{ padding: '0' }" style="width:62.5%;margin: 20px auto;" class="box-card">
      <div class="newContent">
        <div class="enterprise" style="width: 90%;text-align: center;padding: 49px 0 50px 0;margin:0 auto;">
          <p class="enterprise-title" style="font-size: 24px;">
            {{ data.title }}
          </p>
          <p class="p1" style="margin-bottom: 29px;">
            <span>发布时间：{{ data.creationTime }}</span>
          </p>
          <div class="ql-wrap">
            <div class="ql-snow">
              <div class="ql-editor" v-html="data.content"></div>
            </div>

            <p v-if="data.header" style="font-size: 14px;margin: 50px 30px 5px 0;text-align: right;">
              图：<span>{{ data.header }}</span>
            </p>
            <p v-if="data.footer" style="font-size: 14px;text-align: right;margin: 0 30px 30px 0;">
              文：<span>{{ data.footer }}</span>
            </p>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "adressMaintain",
  computed: {
    ...mapState({
      get_NewsDetail: (state) => {
        return state.commonProblem.get_NewsDetail;
      },
    }),
  },
  data() {
    return {
      currentPage3: 1,
      value: "",
      activeName: "1",
      activeName2: "12",
      activeName3: "22",
      form: {
        name: "",
        type: [],
        desc: "",
      },
      id: "",
      data: [],
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.value = this.$route.query.value;
    this.active = this.$route.query.value;
    if (this.value == undefined) {
      this.value = 1;
    }
    if (this.active == undefined) {
      this.active = "1";
    }
  },
  mounted() {
    this.getNews();
  },
  methods: {
    getNews() {
      this.$store
        .dispatch("commonProblem/NewsDetail", {
          id: this.id,
        })
        .then(() => {
          this.data = this.get_NewsDetail;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.ql-wrap {
  width: 860px; /* no */
  margin: 0 auto;
}
.banner {
  text-align: center;
  height: 480px;
  background-image: url(/img/lianxibg.png);
  background-size: 100%;
  line-height: 480px;
}

.enterprise {
  .p1 {
    padding-top: 20px;
    span {
      font-size: 14px;
      margin-right: 29px;
    }
  }
  .enterprise-title {
    width: 860px; /* no */
    margin: 19px auto 0;
    padding-bottom: 14px;
    border-bottom: 1px dashed rgb(46 196 205); /* no */
  }
}

.time h2 {
  color: #ff6600;
  margin: 20px auto 30px auto;
}
.time-line {
  position: relative;
  width: 215px;
  margin: 0 auto;
  margin-top: 84px;
}
.time-line-div {
  position: relative;
  min-height: 85px;
}
.time-line-div > p:nth-child(1) {
  position: absolute;
  left: -200px;
  width: 240px;
  text-align: right;
  .sp1 {
    font-size: 24px;
    display: block;
    margin-bottom: 29px;
  }
  .sp2 {
    font-size: 16px;
    display: block;
    margin-bottom: 15px;
  }
  .img1 {
    width: 180px;
    height: 135px;
    position: absolute;
    left: -90px;
  }
}
.time-line-div > p:nth-child(2) {
  position: absolute;
  left: 100px;
  width: 16px;
  height: 16px;
  top: 10px;
  // background:#5CB85C;
  // border-radius: 50%;
  z-index: 10;
  background: url(/img/dashiji1.png);
}
.time-line-div > p:nth-child(3) {
  position: absolute;
  left: 100px;
  width: 16px;
  height: 16px;
  top: 120px;
  // background:#5CB85C;
  // border-radius: 50%;
  z-index: 10;
  background: url(/img/dashiji1.png);
}
.time-line-div > p:nth-child(4) {
  position: absolute;
  left: 170px;
  padding: 10px;
  font-size: 24px;
  border-radius: 10px;
  width: 225px;
  top: 105px;
  ._sp1 {
    font-size: 24px;
    display: block;
    margin-bottom: 29px;
  }
  ._sp2 {
    font-size: 16px;
    display: block;
    margin-bottom: 15px;
  }
  .img1 {
    width: 180px;
    height: 135px;
    position: absolute;
    left: 200px;
  }
}
.img-dotted {
  position: absolute;
  width: 1px;
  height: 3000px;
  top: 0;
  z-index: 1;
  background: #dddddd;
  left: 107.5px;
  // background:url('/static/images/vue/dotted.png');
}
.time-line-detail > p {
  margin-left: 30px;
  margin-bottom: 10px;
}
</style>
